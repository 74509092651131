<template>
  <div>
    <div class="header">
        <label class="home-btn" @click="toIndex">首页</label>
        <label class="arrow"> > </label>
        <label class="current-btn">牛人详情</label>
    </div>
    <div class="detail-container">

        <el-card class="base-info-card">
            <div class="base-info">

                <el-image :src="detail.cover_img" class="cover-img"></el-image>
                <div class="content">
                    <div class="project-desc">{{detail.project_desc}}</div>
                    <div class="tags">{{detail.skill_label}}</div>
                    <div class="address">
                        <label class="section-title">地点：</label>
                        <label class="section-content">{{detail.address}}</label>
                    </div>
                    <div class="username">
                        <label class="section-title">联系人：</label>
                        <label class="section-content">{{detail.username}}</label>
                    </div>
                    <div class="mobile-box" @click="clickMobile">

                        <div class="mobile">
                            <label class="mobile-label">立即联系</label>
                            <i class="el-icon-phone"></i>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </el-card>
        <el-card v-if="detail.skill_desc" class="skill-desc">
            <div class="card-title">技能介绍</div>
            <div class="card-content">{{detail.skill_desc}}</div>
        </el-card>
        <el-card v-if="detail.service_desc" class="service-desc">
            <div class="card-title">服务介绍</div>
            <div class="card-content">{{detail.service_desc}}</div>
        </el-card>
        <el-card v-if="detail.works && detail.works.length > 0" class="works">
            <div class="card-title">作品展示</div>
            <div class="card-content">
                <el-image v-for="work in detail.works" :src="work.url" :key="work.id" class="work-img"></el-image>
            </div>
        </el-card>
    </div>
    <el-dialog
      title="拨打电话"
      :visible.sync="showMobile"
      width="400px"
      center>
      <div class="mobile-dialog">
        <div class="mobile-content">{{currentMobile}}</div>
        <label class="mobile-desc">电话有效期还剩（</label>
        <label class="mobile-second">{{mobileSecond}}</label>
        <label class="mobile-desc">）秒，请尽快拨打</label>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showMobile = false">关 闭</el-button>
        <el-button type="primary" @click="showMobile = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'App',
  data() {
      return {
        detail: {},
        mobileSecond: 0,
        mobileInternal: undefined,
        showMobile: false,
        currentMobile: 0,
        currentId: 0,
      }
  },
  computed: {
    ...mapState({
    //   currentId: (state) => state.project.currentId,
      
    })
  },
  mounted() {
      console.log(this)
       if (this.$route.query.id) {
            this.currentId = this.$route.query.id
        }
      this.loadDetail()
  },
  methods: {
    toIndex() {
      this.$router.push({name: "index"})
    },
    loadDetail() {
        this.axios({
            method: 'get',
            url: '/nrb_service/skill/get_skill_info',
            params: {
                id: this.currentId
            }
        }).then(res => {
        console.log(res)
        if (res.status === 200 && res.data.code === 'A00000') {
            this.detail = res.data
            
            
        } else {
            this.$message({
            type: 'error',
            message: res.data.msg
            });
        }
        })
    },
    clickCategory(item) {

    },
    clickMobile() {
      this.currentMobile = this.detail.mobile
      this.showMobile = true
      this.mobileSecond = 180
      if (this.mobileInternal) {
        clearInterval(this.mobileInternal)
      }
      this.mobileInternal = setInterval(() => {
        this.mobileSecond -= 1
      }, 1000);
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-container {
    margin-top: 30px;


    .base-info {
        display: flex;
        padding: 15px;

        .cover-img {
            width: 500px;
            height: 300px;
        }

        .content {
            display: flex;
            flex-direction: column;
            margin-left: 15px;

            .project-desc {
                font-size: 20px;
                font-weight: bold;
            }

            .tags {
                margin-top: 25px;
                font-size: 16px;
                color: rgb(86, 199, 252);
            }

            .address {
                margin-top: 25px;
            }

            .username {
                margin-top: 25px;
            }

            .section-title {
                font-size: 16px;
                color: gray;
            }

            .section-content {
                font-size: 16px;
                color: #333;
            }

            .mobile-box {
                cursor: pointer;
                margin-top: 25px;
                

                .mobile {
                    cursor: pointer;
                    background-color: orange;
                    color: white;
                    border-radius: 6px;
                    padding: 8px 16px;
                    font-size: 16px;
                    display: inline-block;

                    .mobile-label {
                        cursor: pointer;
                        margin-right: 10px;
                    }
                }

            }

        }
    }

    .skill-desc {
        margin-top: 20px;
        padding: 20px;
    }

    .service-desc {
        margin-top: 20px;
        padding: 20px;
    }

    .works {
        margin-top: 20px;
        padding: 20px;

        .work-img {
            width: 250px;
            height: 150px;
            margin: 20px;
        }
    }

    .card-title {
        font-size: 16px;
        font-weight: 700;
        color: #666;
    }

    .card-content {
        word-break: break-all;
        white-space: normal;
        margin-top: 20px;
        color: #333;
        font-size: 14px;
    }

}


.mobile-dialog {
  padding: 20px;
  text-align: center;
}

.mobile-content {
  font-size: 30px;
  color: #333;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}
.mobile-desc {
  font-size: 16px;
  color: gray;
}
.mobile-second {
  color: red;
  font-size: 16px;
}

.header {
    margin-top: 30px;
    font-size: 18px;
    line-height: 20px;
}

.home-btn {
    cursor: pointer;
    font-weight: bold;
}

.current-btn {
    color: gray;
}

</style>>